import { Link } from "gatsby";
import React from "react";
import { Seo } from "../components/Layout/Seo";

const NotFoundPage = ({ lang }) => {
  const error404Container = {
    maxWidth: "600px",
    minHeight: "90vh",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundRepeat: "no-repeat",
    display: "block",
    backgroundSize: "cover",
    paddingTop: "120px",
    marginTop: "-120px",
    textAlign: "center",
  };

  const error404Title = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "104px",
    lineHeight: "90px",
    color: "#ffffff",
    letterSpacing: "-0.5px",
    marginBottom: "25px",
    marginTop: "200px",
  };

  const error404Paragraph = {
    width: "auto",
    maxWidth: "600px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "34px",
    textAlign: "center",
    color: "#ffffff",
    margin: "auto",
  };

  const error404ButtonsContainer = {
    display: "flex",
    padding: 0,
    marginTop: "25px",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "30px",
  };

  const error404ReturnHome = {
    alignItems: "flex-start",
    background: "hsla(0, 0%, 100%, .2)",
    outline: "1px solid #fff",
    borderRadius: "100px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginZRight: "25px",
    width: "170px",
    padding: "8px 24px",
  };

  const error404GoSendMoney = {
    alignItems: "flex-start",
    background: "#24769d ",
    borderRadius: "100px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginZRight: "25px",
    width: "170px",
    padding: "8px 24px",
  };

  const textObj = {
    es: {
      p: "La página que estás buscando no existe. Puedes volver a la página de inicio para más información o para hacer un envío de dinero.",
      link1: "Página de inicio",
      link2: "Enviar Dinero",
    },
    en: {
      p: "The content you are looking for does not exist or have been moved from its previous location. You can go back to the home page for more information about our service, or go directly to send a remittance",
      link1: "Homepage",
      link2: "Send money",
    },
  };

  return (
    <Seo lang={lang}>
      <div className="background-404">
        <div style={error404Container}>
          <h1 style={error404Title}>404</h1>
          <p style={error404Paragraph}>{textObj[lang].p}</p>
          <ul style={error404ButtonsContainer}>
            <li style={error404ReturnHome}>
              <Link style={{ color: "#ffffff" }} to="/">
                {textObj[lang].link1}
              </Link>
            </li>
            <li style={error404GoSendMoney}>
              <Link style={{ color: "#ffffff" }} to="/enviar-dinero">
                {textObj[lang].link2}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Seo>
  );
};

export default NotFoundPage;
